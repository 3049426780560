import React from "react";
import styles from "./specializationsComponent.module.css";

const SpecializationsComponent = () => {
  return (
    <div className={styles.spz_container}>
      <div className={styles.spz_heading}>We are specialized in</div>
      <div className={styles.specializations}>
        <div className={styles.item}>
          <div className={`${styles.circle}`} />
          <div className={styles.spz_text}>IT Consulting</div>
        </div>
        <div className={styles.item}>
          <div className={`${styles.circle}`} />
          <div className={styles.spz_text}>Custom & Full-Stack Solutions</div>
        </div>
        <div className={styles.item}>
          <div className={`${styles.circle}`} />
          <div className={styles.spz_text}>Cloud Solutions & Infrastructures</div>
        </div>
        <div className={styles.item}>
          <div className={`${styles.circle}`} />
          <div className={styles.spz_text}>Cybersecurity Solutions</div>
        </div>
        <div className={styles.item}>
          <div className={`${styles.circle}`} />
          <div className={styles.spz_text}>IT Support & Managed Services</div>
        </div>
        <div className={styles.item}>
          <div className={`${styles.circle}`} />
          <div className={styles.spz_text}>Mobile and Web Applications</div>
        </div>
      </div>
    </div>
  );
};

export default SpecializationsComponent;
