import React from "react";
import { Card } from "react-bootstrap";
import styles from "./../components/landing/LandingComponent.module.css";
import "bootstrap/dist/css/bootstrap.min.css";

export const ServiceCard = ({ service }) => {
  return (
    <div className="col-lg-6 col-md-12 col-sm-12 mb-5">
      <Card className={`${styles.card} position-relative`}>
        <div className={`${styles.card_icon}`}>
          <img src={service.image} alt={service.title} className="img-fluid" />
        </div>
        <Card.Body className={`${styles.card_body_custom}`}>
          <h3 className={`${styles.card_title}`}>
            <strong>{service.title}</strong>
          </h3>
          <p>{service.description}</p>
        </Card.Body>
      </Card>
    </div>
  );
};

export const scrollToSection = (sectionId) => {
  const section = document.getElementById(sectionId);
  if (section) {
    section.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }
};
