import React, { useState } from "react";
import styles from "./ContactUsComponent.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { getFirestore, collection, addDoc } from "firebase/firestore";
import firebaseApp from "../../firebaseConfig";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ContactUsComponent = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    requirement: "",
    message: "",
  });

  const db = getFirestore(firebaseApp);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const contactUsRef = collection(db, "contactUs");
      await addDoc(contactUsRef, formData);
      toast.success("Form submitted successfully!");
      setFormData({
        name: "",
        email: "",
        phoneNumber: "",
        requirement: "",
        message: "",
      });
    } catch (error) {
      toast.error("Error submitting form. Please try again.");
    }
  };

  return (
    <div className={styles.contactContainer}>
      <h1 className={styles.contactTitle}>Contact Us</h1>
      <div className={styles.contactContent}>
        <div className={styles.contactInfo}>
          <div className={styles.infoBox}>
            <div className={styles.icon}>
              <FontAwesomeIcon icon={faPhone} className={styles.iconImage} />
            </div>
            <h3 className={styles.infoTitle}>PHONE NUMBER</h3>
            <p className={styles.infoText}>+91-9490432904 / +91-9848043777</p>
          </div>
          <div className={styles.infoBox}>
            <div className={styles.icon}>
              <FontAwesomeIcon icon={faEnvelope} className={styles.iconImage} />
            </div>
            <h3 className={styles.infoTitle}>MAIL US</h3>
            <a className={styles.infoText} href="mailto:support@gorush.co">
              support@gorush.co
            </a>
          </div>
        </div>

        <div className={styles.contactForm}>
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              name="name"
              value={formData.name}
              placeholder="Name"
              className={styles.formInputBlock}
              onChange={handleChange}
              required
            />
            <input
              type="email"
              name="email"
              value={formData.email}
              placeholder="Email"
              className={styles.formInputBlock}
              onChange={handleChange}
              required
            />
            <input
              type="text"
              name="phoneNumber"
              value={formData.phoneNumber}
              placeholder="Phone Number"
              className={styles.formInputBlock}
              onChange={handleChange}
              required
            />
            <input
              type="text"
              name="requirement"
              value={formData.requirement}
              placeholder="Enter Your Requirement"
              className={styles.formInputBlock}
              onChange={handleChange}
              required
            />
            <textarea
              name="message"
              value={formData.message}
              placeholder="Message"
              className={styles.formTextareaBlock}
              onChange={handleChange}
              required
            ></textarea>
            <button type="submit" className={styles.formSubmit}>
              Submit
            </button>
          </form>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ContactUsComponent;
