import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "bootstrap/dist/css/bootstrap.min.css";
import styles from "./HeaderComponent.module.css";
import logo from "./../../assets/logo.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { scrollToSection } from "../../commonComponent/commonComponent";

const HeaderComponent = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [targetSection, setTargetSection] = useState("");

  const handleNavToggle = () => {
    setIsNavOpen(!isNavOpen);
  };

  const handleLinkClick = () => {
    setIsNavOpen(false);
  };

  const navLinks = [
    { label: "About Us", sectionId: "aboutUs-section", route: "/" },
    { label: "Services", sectionId: "services-section", route: "/" },
    { label: "Projects", sectionId: "projects-section", route: "/" },
    { label: "Team", sectionId: "team-section", route: "/" },
    { label: "Contact Us", sectionId: "contactus-section", route: "/" },
  ];

  useEffect(() => {
    if (targetSection) {
      scrollToSection(targetSection);
      setTargetSection("");
    }
  }, [location, targetSection]);

  return (
    <Navbar
      expand="lg"
      className={`bg-body-tertiary ${styles.custom_navbar} fixed-top`}
    >
      <Container fluid>
        <Navbar.Brand
          href="#"
          onClick={(e) => {
            e.preventDefault();
            navigate("/");
            setTargetSection("landing-section");
            handleLinkClick();
          }}
        >
          <img src={logo} alt="logo" className="img-fluid" />
        </Navbar.Brand>
        <div
          className={`navbar-toggler ${isNavOpen ? "collapsed" : ""} ${
            styles.navbar_toggler
          }`}
          onClick={handleNavToggle}
          aria-label="Toggle navigation"
          aria-expanded={isNavOpen ? "true" : "false"}
        >
          <FontAwesomeIcon icon={faBars} size="lg" />
        </div>
        <Navbar.Collapse id="navbarScroll" in={isNavOpen}>
          <Nav
            className="ms-auto my-2 my-lg-0"
            style={{ maxHeight: "100px" }}
            navbarScroll
          >
            {navLinks.map((link, index) => (
              <Nav.Link
                key={index}
                href="#"
                className={`me-3 ${styles.custom_nav_link}`}
                onClick={(e) => {
                  e.preventDefault();
                  if (location.pathname !== link.route) {
                    navigate(link.route);
                  }
                  setTargetSection(link.sectionId);
                  handleLinkClick();
                }}
              >
                {link.label}
              </Nav.Link>
            ))}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default HeaderComponent;
