import React from "react";
import styles from "../Projects/ProjectsComponent.module.css";
import ScremerImage from "../../assets/scremer.svg"; 
import ReturNationImage from "../../assets/returnation.svg"; 
import MyCampusManageImage from "../../assets/MyCampusManage.svg"; 
import RideHailingImage from "../../assets/EZwallet.svg"; 
import PosImage from "../../assets/POS.svg";

const projects = [
  {
    title: "Scremer - A Social Feedback App",
    description:
      "Scremer is a social feedback platform designed for better analytics, enabling users to share complaints with brands and companies. It leverages AI to assist businesses in efficiently addressing user feedback, ensuring customer satisfaction.",
    image: ScremerImage,
  },
  {
    title: "ReturNation",
    description:
      "ReturNation is a Utrecht-based startup that handles the returns of eCommerce companies in a sustainable way in The Netherlands. Rush Technologies teamed up with ReturNation and built a system that efficiently handles their returns business and seamlessly integrates with existing small businesses. Built plugins for Shopify and Magento frameworks for further plug-and-play integration.",
    image: ReturNationImage, 
  },
  {
    title: "My Campus Manage",
    description:
      "My campus management is a cloud-based school ERP system that helps to automate and maintain the data of a school’s day-to-day tasks right from children’s attendance. This includes the mobile app customizable with the school logo for both Apple and Android. Features include: Attendance, Exams, Activity alert for parents, Report cards, School bus tracking, and Payments.",
    image: MyCampusManageImage, 
  },
  {
    title: "Ride Hailing Mobile Application",
    description:
      "An innovative platform connecting passengers with reliable transportation services, designed for user-friendliness and efficiency. Key Features include Real-time ride tracking, Fare estimation, Secure payment options, and Quick access to safe & efficient rides.",
    image: RideHailingImage, 
  },
  {
    title: "POS & Customer Automation System for Retail Tyre Franchisee",
    description:
      "This project involved developing a comprehensive payment system tailored specifically for a tyre company. It includes a robust point-of-sale (POS) system with multiple selective options, integrated with WhatsApp and email for seamless communication. The system is designed for both web portals and mobile applications, offering flexibility and ease of use across platforms.",
    image: PosImage,
  },
];

const ProjectsComponent = () => {
    return (
      <div className={styles.project_container}>
        <h1 className={styles.project_title}>Recent Projects</h1>
        <div className={styles.main_content}>
          {projects.map((project, index) => (
            <div
              key={index}
              className={`${styles.projectContent} ${
                index % 2 === 0 ? styles.leftContent : styles.rightContent
              }`}
            >
              <img
                src={project.image}
                alt={project.title}
                className={styles.project_image}
              />
              <div className={styles.project_text}>
                <h2 className={styles.projectTitle}>{project.title}</h2>
                <p className={styles.project_description}>{project.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };
  
  export default ProjectsComponent;