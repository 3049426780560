import React from "react";
import { Routes, Route } from "react-router-dom";
import LandingComponent from "../components/landing/LandingComponent";
import TermsConditionsComponent from "../components/terms-and-conditions/TermsConditionsComponent";
import PrivacyPolicyComponent from "../components/privacy-and-policy/PrivacyPolicyComponent";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<LandingComponent />} />
      <Route path="/terms-conditions" element={<TermsConditionsComponent />} />
      <Route path="/privacy-policy" element={<PrivacyPolicyComponent />} />
    </Routes>
  );
};

export default AppRoutes;
