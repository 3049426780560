import React from "react";
import styles from "./AboutUsComponent.module.css";
import aboutUsImage from "../../assets/team.svg";

const AboutUsComponent = () => {
  return (
    <div className={styles.aboutUs_container}>
      <h1 className={styles.aboutUs_title}>About Us</h1>
      <div className={styles.aboutUs_grid}>
        <div className={styles.aboutUs_imageSection}>
          <img
            src={aboutUsImage}
            alt="Team working together"
            className={styles.aboutUs_image}
          />
        </div>
        <div className={styles.aboutUs_textSection}>
          <p>
            Rush Technologies is a dynamic startup driven by a dedicated team of
            passionate professionals. Though young, we've built a solid
            foundation by leveraging our expertise and industry knowledge to
            deliver cutting-edge IT solutions. Our mission is to transform ideas
            into innovative technologies that meet the unique needs of our
            clients. From IT consulting and software development to cloud
            services and custom solutions, we cater to a wide range of
            requirements. No matter the challenge, we ensure that each project
            is tailored to the exact specifications of our clients, delivering
            results that fuel growth and success.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUsComponent;
