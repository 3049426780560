import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import styles from "./LandingComponent.module.css";
import fullStackIcon from "./../../assets/full-stack-icon.svg";
import commonIcon from "./../../assets/design-icon.svg";
import databaseIcon from "./../../assets/data-base.svg";
import {
  scrollToSection,
  ServiceCard,
} from "../../commonComponent/commonComponent";
import AboutUsComponent from "../about-us/AboutUsComponent";
import FoundingTeamComponent from "../FoundingTeam/FoundingTeamComponent";
import ProjectsComponent from "../Projects/ProjectsComponent";
import ReadilySoftwareComponent from "../readilySoftwares/ReadilySoftwareComponent";
import ContactUsComponent from "../contact-us/ContactUsComponent";
import SpecializationsComponent from "../specializations/specializationsComponent";

const LandingComponent = () => {
  const services = [
    {
      title: "Full Stack Development",
      description:
        "Development from scratch and we use tools of Java,Spring boot micro services,Angular,React JS.",
      image: fullStackIcon,
    },
    {
      title: "UI/UX Design",
      description:
        "Designing user-friendly interfaces. From wireframes to final designs, we ensure seamless user experiences. ",
      image: commonIcon,
    },
    {
      title: "Data Bases",
      description:
        "SQL and NoSQL databases, including MySQL, PostgreSQL, and MongoDB.",
      image: databaseIcon,
    },
    {
      title: "Data Analytics",
      description:
        "Using Artificial Intelligence and Machine Learning to provide data-driven solutions.",
      image: commonIcon,
    },
  ];
  return (
    <div>
      <section
        className={` ${styles.background_section} `}
        id="landing-section"
      >
        <div className={` ${styles.content_container} `}>
          <h1 className={` ${styles.title} pb-3`}>
            <strong>Transforming Ideas into Scalable Tech Solutions.</strong>
          </h1>
          <p className={` ${styles.content} `}>
            We turn your ideas into scalable tech solutions. Our team designs
            apps that grow with your business and work smoothly as your needs
            change. Whether you're a startup or a large company, we create
            technology that drives your success.
          </p>
          <button
            className={` ${styles.contactBtn} mt-3`}
            onClick={(e) => {
              e.preventDefault();
              scrollToSection("contactus-section");
            }}
          >
            Contact Us
          </button>
        </div>
      </section>
      <div id="services-section">
        <div className={` ${styles.content_container} `}>
          <h1 className={` ${styles.title} pb-3 text-center`}>
            <strong>Services we offer</strong>
          </h1>
          <div className="container pt-5">
            <div className="row">
              {services.slice(0, 2).map((service, index) => (
                <ServiceCard key={index} service={service} />
              ))}
            </div>

            <div className="row">
              {services.slice(2, 4).map((service, index) => (
                <ServiceCard key={index} service={service} />
              ))}
            </div>
          </div>
        </div>
      </div>
      <div id="specialization-section">
        <SpecializationsComponent></SpecializationsComponent>
      </div>
      <div id="projects-section">
        <ProjectsComponent></ProjectsComponent>
      </div>
      <div id="aboutUs-section">
        <AboutUsComponent></AboutUsComponent>
      </div>
      <div id="softwares-section">
        <ReadilySoftwareComponent></ReadilySoftwareComponent>
      </div>
      <div id="team-section">
        <FoundingTeamComponent></FoundingTeamComponent>
      </div>
      <div id="contactus-section">
        <ContactUsComponent></ContactUsComponent>
      </div>
    </div>
  );
};
export default LandingComponent;
