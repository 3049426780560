import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyBiYyiRnDmCsen8sI6bCFjDmVW-rhSe2dg",
  authDomain: "rushtechnologies-26e70.firebaseapp.com",
  projectId: "rushtechnologies-26e70",
  storageBucket: "rushtechnologies-26e70.firebasestorage.app",
  messagingSenderId: "1065535940566",
  appId: "1:1065535940566:web:828bedaa4dd6e6c0182677",
  measurementId: "G-CB0DV45R2Y"
};

const app = initializeApp(firebaseConfig);
export default app;