import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import whatsapp from "../../assets/whatsapp.svg";
import linkedin from "../../assets/linkedin.svg";
import instagram from "../../assets/instagram.svg";
import "bootstrap/dist/css/bootstrap.min.css";
import styles from "./FooterComponent.module.css";
import { useLocation, useNavigate } from "react-router-dom";

const Footer = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const currentYear = new Date().getFullYear();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const scrollTo = queryParams.get("scrollTo");

    if (scrollTo) {
      const section = document.getElementById(scrollTo);
      if (section) {
        section.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }
  }, [location]);

  return (
    <footer className={styles.footer}>
      <Container className="pt-3 mt-3">
        <Row>
          <Col md={3} className="mb-4">
            <p
              className={`${styles.text} ${styles.pointer}`}
              onClick={(e) => {
                e.preventDefault();
                navigate("/?scrollTo=services-section");
              }}
            >
              Services
            </p>
            <p
              className={`${styles.text} ${styles.pointer}`}
              onClick={(e) => {
                e.preventDefault();
                navigate("/?scrollTo=projects-section");
              }}
            >
              Projects
            </p>
            <p
              className={`${styles.text} ${styles.pointer}`}
              onClick={(e) => {
                e.preventDefault();
                navigate("/?scrollTo=softwares-section");
              }}
            >
              Available Softwares
            </p>
          </Col>
          <Col md={3} className="mb-4">
            <p
              className={`${styles.text} ${styles.pointer}`}
              onClick={(e) => {
                e.preventDefault();
                navigate("/?scrollTo=aboutUs-section");
              }}
            >
              About Us
            </p>
            <p
              className={`${styles.text} ${styles.pointer}`}
              onClick={(e) => {
                e.preventDefault();
                navigate("/?scrollTo=team-section");
              }}
            >
              Founding Team
            </p>
            <p
              className={`${styles.text} ${styles.pointer}`}
              onClick={(e) => {
                e.preventDefault();
                navigate("/?scrollTo=contactus-section");
              }}
            >
              Contact Us
            </p>
          </Col>
          <Col md={3} className="mb-4">
            <p>
              <a href="/terms-conditions" className={styles.text}>
                Terms and Conditions
              </a>
            </p>
            <p>
              <a href="/privacy-policy" className={styles.text}>
                Privacy Policy
              </a>
            </p>
          </Col>
          <Col md={3} className="text-center mb-4">
            <div>
              <h6 className={styles.Caption}>Follow</h6>
              <div className="d-flex gap-3">
                <a
                  href="https://wa.me/9848043777"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={whatsapp} alt="WhatsApp" />
                </a>
                <a
                  href="https://www.linkedin.com/company/rush-cabs"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={linkedin} alt="LinkedIn" />
                </a>

                <img src={instagram} alt="Instagram" />
              </div>
            </div>
          </Col>
        </Row>
        <div className={`${styles.bottomSection} text-center mt-4`}>
          <p>All Rights Reserved &copy; Rush Technologies, {currentYear}</p>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
