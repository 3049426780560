import { BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import HeaderComponent from "./components/header/HeaderComponent";
import AppRoutes from "./routes";
import FooterComponent from "./components/footer/FooterComponent";

const App = () => {
  return (
    <div>
      <Router>
        <HeaderComponent></HeaderComponent>
        <AppRoutes />
        <FooterComponent></FooterComponent>
      </Router>
    </div>
  );
};

export default App;
