import React from "react";
import styles from "./FoundingTeamComponent.module.css";
import image1 from "../../assets/mem4.svg";
import image2 from "../../assets/mem3.svg";
import image3 from "../../assets/mem2.svg";
import image4 from "../../assets/mem1.svg";

const FoundingTeamComponent = () => {
  const teamMembers = [
    {
      name: "Satya Metla",
      role: "(Founder & Head)",
      image: image1,
    },
    {
      name: "Sri Harsha Baragada",
      role: "(Co-Founder and CTO)",
      image: image2,
    },
    {
      name: "Vasavi Arigela",
      role: "(Managing Partner)",
      image: image3,
    },
    {
      name: "Ashok Vardhan Allada",
      role: "(Managing Partner)",
      image: image4,
    },
  ];

  return (
    <div className={styles.team_container}>
      <h1 className={styles.team_title}>Founding Team</h1>
      <div className={styles.team_cardContainer}>
        {teamMembers.map((member, index) => (
          <div className={styles.team_cardColumn} key={index}>
            <div className={styles.team_card}>
              <img src={member.image} alt={member.name} />
              <div className={styles.team_cardBody}>
                <h5 className={styles.team_cardTitle}>{member.name}</h5>
                <p className={styles.team_cardRole}>{member.role}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FoundingTeamComponent;
