import React from 'react';
import styles from './ReadilySoftwareComponent.module.css';
import MyCampusManageImage from "../../assets/School.svg"; 
import RideHailingImage from "../../assets/ride.svg"; 
import PosImage from "../../assets/POS_new.svg"; 

const softwareData = [
  {
    title: 'Standard POS Systems',
    description: 'Custom options available for both Application & Website',
    image: PosImage,
  },
  {
    title: 'Schooling Management System',
    description: 'Both application & website',
    image: MyCampusManageImage,
  },
  {
    title: 'Ride Hailing Platform',
    description: 'User & Driver Application with Backend Dashboard',
    image: RideHailingImage,
  },
];

const ReadilySoftwareComponent = () => {
  return (
    <div className={styles.software_container}>
      <h1 className={styles.software_header}>Readily Available Softwares</h1>
      <div className={styles.software_gridContainer}>
        {softwareData.map((software, index) => (
          <div
            key={index}
            className={`${styles.software_card} ${
              index === 2 ? styles.software_fullWidthCard : ''
            }`}
          >
            <h2 className={styles.software_cardTitle}>{software.title}</h2>
            <img
              src={software.image}
              alt={software.title}
              className={styles.software_cardImage}
            />
            <p className={styles.software_cardDescription}>{software.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ReadilySoftwareComponent;
